import * as yup from 'yup'
import { useTranslation } from '@local/translations'
import { BankAccount } from '../../../../../api-documents'

export const useDirectDepositPercentSplitSchema = () => {
  const { t } = useTranslation()

  return (schema: any) =>
    schema
      .of(
        yup
          .object({
            percentage: yup
              .number()
              .required(t('direct-deposit-split-modal.percent-required'))
              .min(0, t('direct-deposit-split-modal.percent-min-error'))
              .max(100, t('direct-deposit-split-modal.percent-max-error'))
          })
          .required()
      )
      .test(
        'is-sum-100',
        t('direct-deposit-split-modal.percent-error'),
        (bankAccounts: BankAccount[]) => {
          const sum = (bankAccounts || []).reduce(
            (total, bankAccount) => total + (bankAccount.percentage || 0),
            0
          )
          return sum === 100
        }
      )
}
