import * as React from 'react'
import cx from 'classnames'
import { useMutation, useQuery } from '@apollo/client'

import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Modal } from '@toasttab/buffet-pui-modal'
import { RadioButton, RadioButtonProps } from '@toasttab/buffet-pui-radio-group'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

import {
  trackSendDirectDepositInfoRequestModal,
  useTracking
} from '@local/track'
import { CtaRadioButton } from '@local/cta-radio-button'
import { SendUpdateEmployeePaymentMethodEmailMutation } from '@local/grouter'
import { useEmployee } from '@local/hooks'
import { useTranslation } from '@local/translations'

import {
  EmployeeForDirectDeposit,
  SendUpdateEmployeePaymentMethodEmail
} from './api-documents'
import { isValidEmail, useEmailOption, useManualOption } from './utils'
import { InvalidEmailAlert } from './InvalidEmailAlert'
import { useNavigateToLegacyDirectDepositLink } from '@toasttab/ec-session'

export type DirectDepositInfoRequestOption = 'email' | 'manual' | undefined

type SendDirectDepositInfoRequestModalProps = {
  onClose: VoidFunction
}

export const SendDirectDepositInfoRequestModal: React.FunctionComponent<
  SendDirectDepositInfoRequestModalProps
> = ({ onClose }) => {
  const [selectedOption, setSelectedOption] =
    React.useState<DirectDepositInfoRequestOption>(undefined)
  const { t } = useTranslation()

  const employee = useEmployee()

  const { data, loading } = useQuery(EmployeeForDirectDeposit, {
    variables: { employeeId: employee.id }
  })

  const employeeEmail = data?.findEmployeeById?.user?.email
  const isEmailValid = isValidEmail(employeeEmail)

  const { track } = useTracking()

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        track(
          'employee-payment-method.send-direct-deposit-info-request-modal.close'
        )
        onClose()
      }}
      size='xxl'
    >
      {loading && (
        <div className='h-80'>
          <MerryGoRound className='pin-center' />
        </div>
      )}
      {!loading && (
        <>
          <Modal.Header>
            {t('direct-deposit-modal.heading', {
              employeeFirstName: employee.firstName
            })}
          </Modal.Header>
          <Modal.Body>
            <div className='space-y-6'>
              <div>{t('direct-deposit-modal.subheading')}</div>
              <div>
                <InvalidEmailAlert isEmailValid={isEmailValid} />
                <DirectDepositModalRadioGroup
                  selectedOption={selectedOption}
                  isEmailValid={isEmailValid}
                  employeeEmail={employeeEmail}
                  onChange={(e) =>
                    setSelectedOption(
                      e?.target?.value as DirectDepositInfoRequestOption
                    )
                  }
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <DirectDepositModalButton
              onClose={onClose}
              selectedOption={selectedOption}
            />
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

type DirectDepositModalRadioGroupProps = {
  employeeEmail?: string | null
  isEmailValid: boolean
  onChange?: RadioButtonProps['onChange']
  selectedOption: DirectDepositInfoRequestOption
}

const DirectDepositModalRadioGroup: React.FunctionComponent<
  DirectDepositModalRadioGroupProps
> = ({ employeeEmail, isEmailValid, onChange, selectedOption }) => {
  const name = 'direct-deposit-modal-options'
  const emailOption = useEmailOption(
    selectedOption,
    isEmailValid,
    employeeEmail
  )
  const manualOption = useManualOption(selectedOption)
  return (
    <>
      {emailOption && (
        <CtaRadioButton
          {...emailOption}
          containerClassName={cx({ 'mb-4': !!emailOption?.cta?.stacked })}
          key='direct-deposit-modal-option-email'
          name={name}
          onChange={onChange}
        />
      )}
      <RadioButton
        {...manualOption}
        key='direct-deposit-modal-option-manual'
        name={name}
        onChange={onChange}
      />
    </>
  )
}

type DirectDepositModalButtonProps = {
  onClose: VoidFunction
  selectedOption: DirectDepositInfoRequestOption
}

const DirectDepositModalButton: React.FunctionComponent<
  DirectDepositModalButtonProps
> = ({ onClose, selectedOption }) => {
  const { t } = useTranslation()

  const sendEmail = useSendEmail(onClose)
  const navigateToLegacyDirectDeposit = useNavigateToLegacyDirectDepositLink(
    useEmployee().id
  )

  const onClick = () => {
    if (selectedOption === 'email') {
      sendEmail()
    } else {
      navigateToLegacyDirectDeposit()
    }
  }

  return (
    <Button
      {...trackSendDirectDepositInfoRequestModal('continue')}
      disabled={!selectedOption}
      onClick={onClick}
    >
      {t('direct-deposit-modal.continue-button')}
    </Button>
  )
}

const useSendEmail = (onClose: VoidFunction) => {
  const { t } = useTranslation()
  const employee = useEmployee()

  const [sendEmail] = useMutation(SendUpdateEmployeePaymentMethodEmail, {
    variables: { employeeId: employee.id }
  })

  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const onCompleted = (
    mutation: SendUpdateEmployeePaymentMethodEmailMutation
  ) => {
    if (
      mutation.sendUpdateEmployeePaymentMethodEmail?.__typename ===
      'EmployeePaymentMethodEmailResponse'
    ) {
      showSuccessSnackBar(
        t('direct-deposit-modal.send-email-success', {
          employeeFirstName: employee.firstName
        }),
        { showIcon: true }
      )
      onClose()
    } else {
      onError()
    }
  }

  const onError = () => {
    showErrorSnackBar(
      t('direct-deposit-modal.send-email-error', {
        employeeFirstName: employee.firstName
      }),
      { showIcon: true }
    )
    onClose()
  }

  return () =>
    sendEmail({
      variables: { employeeId: employee.id },
      onCompleted: onCompleted,
      onError: onError
    })
}
