import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { trackDownloadApp } from '@local/track'
import { useTranslation } from '@local/translations'

export const PayCardEligibility: React.FunctionComponent<TestIdentifiable> = ({
  testId
}) => {
  const { Trans } = useTranslation()
  return (
    <div className='text-center justify-center'>
      <div data-testid={`${testId}-subheader-text`}>
        <Trans
          i18nKey={'download-app-modal.pay-card-eligibility'}
          components={[
            <a
              {...trackDownloadApp('pay-card-eligibility.terms')}
              href='https://pos.toasttab.com/products/toast-pay-card-and-pay-out/#availability'
              target='_blank'
              rel='noopener noreferrer'
            />
          ]}
        />
      </div>
    </div>
  )
}
