import * as React from 'react'
import cx from 'classnames'
import { CardSelectorGroup } from '@toasttab/buffet-pui-card-selector'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

import { useTranslation } from '@local/translations'

import { usePaymentMethodOptions } from './utils'
import { PaperCheckConfirmationModal } from '../../paperCheck'
import { SendDirectDepositInfoRequestModal } from '../../directDeposit'
import { usePaymentMethodMutation } from './api-documents'
import { useEmployee, useIsViewingSelf } from '@local/hooks'

type PaymentMethodSelectorProps = {
  openPlaidModal: VoidFunction
}

export const PaymentMethodSelector: React.FunctionComponent<
  PaymentMethodSelectorProps
> = ({ openPlaidModal }) => {
  const [isDirectDepositModalOpen, setIsDirectDepositModalOpen] =
    React.useState<boolean>(false)

  const [isPaperCheckConfirmationOpen, setIsPaperCheckConfirmationOpen] =
    React.useState<boolean>(false)

  const { t } = useTranslation()

  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const paymentMethodMutation = usePaymentMethodMutation(
    () => showSuccessSnackBar(t('payment-method-change.success')),
    () => showErrorSnackBar(t('payment-method-change.error'))
  )

  const options = usePaymentMethodOptions(
    paymentMethodMutation,
    openPlaidModal,
    () => setIsDirectDepositModalOpen(true),
    () => setIsPaperCheckConfirmationOpen(true)
  )

  const selectedPaymentMethod = useEmployee().paymentMethod
  const isViewingSelf = useIsViewingSelf()
  React.useEffect(() => {
    if (isViewingSelf && selectedPaymentMethod === 'EmptyPaymentMethod') {
      paymentMethodMutation.mutate({
        paymentMethod: 'PayByPaperCheck'
      })
    }
  }, [selectedPaymentMethod])

  return (
    <>
      {isDirectDepositModalOpen && (
        <SendDirectDepositInfoRequestModal
          onClose={() => setIsDirectDepositModalOpen(false)}
        />
      )}
      <PaperCheckConfirmationModal
        isOpen={isPaperCheckConfirmationOpen}
        onCancel={() => setIsPaperCheckConfirmationOpen(false)}
        onConfirm={() => {
          setIsPaperCheckConfirmationOpen(false)
          paymentMethodMutation.mutate({
            paymentMethod: 'PayByPaperCheck'
          })
        }}
      />
      <CardSelectorGroup
        itemsContainerClassName={cx(
          'grid-cols-1',
          options.length === 3 ? 'md:grid-cols-3' : 'md:grid-cols-2'
        )}
        options={options}
      />
    </>
  )
}
