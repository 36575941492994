import * as React from 'react'
import { useQuery } from '@apollo/client'
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { Image } from '@toasttab/buffet-pui-image'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { trackSendDownloadAppLink, useTracking } from '@local/track'
import { useEmployee } from '@local/contexts'
import { useTranslation } from '@local/translations'

import {
  DownloadSteps,
  HeaderContent,
  PayCardActivationDisclaimer,
  PayCardEligibility,
  SmsDisclaimer,
  SuttonDisclaimer
} from '../components'
import {
  SendDownloadAppLinkSchema,
  useSendDownloadAppLinkSchema
} from './domain/SendDownloadAppLinkSchema'
import { SendDownloadAppLinkConfirmationModal } from './SendDownloadAppLinkConfirmationModal'
import { DownloadAppModalProps } from '../DownloadAppModal'
import { POWERED_BY_TOAST_IMAGE_URL } from '../../payCard'
import { NumberInputField } from '@toasttab/buffet-pui-forms'
import {
  EmployeeForDownloadApp,
  useLogDownloadAppLinkSentMutation
} from '../api-documents'
import { useSendDownloadAppLinkMutation } from './api-documents'

export const SendDownloadAppLinkModal: React.FunctionComponent<
  DownloadAppModalProps
> = ({ testId = 'download-app-modal', onClose }) => {
  const { t } = useTranslation()

  const contextEmployee = useEmployee()

  const { data } = useQuery(EmployeeForDownloadApp, {
    variables: { employeeId: contextEmployee.id }
  })
  React.useEffect(() => {
    setFieldValue(
      'phoneNumber',
      data?.findEmployeeById?.contact?.telephoneNumber?.telephoneNumber ?? ''
    )
  }, [data])

  const { showErrorSnackBar } = useSnackBar()

  const sendDownloadAppMutation = useSendDownloadAppLinkMutation(
    () => setShowConfirmationModal(true),
    () => {
      showErrorSnackBar(t('download-app-modal.send-link-error'))
      onClose()
    }
  )

  const downloadAppLinkSentMutation = useLogDownloadAppLinkSentMutation()
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false)

  const sendDownloadAppLinkSchema = useSendDownloadAppLinkSchema()
  const formik = useFormik<SendDownloadAppLinkSchema>({
    initialValues: {
      phoneNumber: ''
    },
    onSubmit: (values: SendDownloadAppLinkSchema) => {
      downloadAppLinkSentMutation.mutate({
        email: data?.findEmployeeById?.user?.email,
        fullName: data?.findEmployeeById?.user?.name?.formatted,
        phoneNumber: values.phoneNumber
      })

      sendDownloadAppMutation.mutate({ phoneNumber: values.phoneNumber })
    },
    validationSchema: sendDownloadAppLinkSchema
  })

  const { handleSubmit, setFieldValue } = formik
  const { track } = useTracking()

  return (
    <>
      {showConfirmationModal && (
        <SendDownloadAppLinkConfirmationModal onClose={onClose} />
      )}
      {!showConfirmationModal && (
        <FormikProvider value={formik}>
          <Form id='send-download-app-link-form' onSubmit={handleSubmit}>
            <Modal
              isOpen={true}
              overflowBehavior='body'
              shouldCloseOnEsc
              onRequestClose={() => {
                track('employee-payment-method.send-download-app-link.close')
                onClose()
              }}
              size='md'
              testId={`${testId}`}
            >
              <ModalHeader>
                <HeaderContent testId={testId} />
              </ModalHeader>
              <ModalBody>
                <div className='type-default text-default'>
                  <PayCardEligibility testId={testId} />
                  <DividingLine />
                  <DownloadSteps />
                </div>
                <PayCardActivationDisclaimer testId={testId} />
                <PhoneNumberInput />
                <SmsDisclaimer />
                <SuttonDisclaimer />
              </ModalBody>
              <ModalFooter>
                <div className='flex flex-col w-full place-items-center'>
                  <SendDownloadAppLinkButton />
                  <Image
                    testId={`${testId}-powered-by-toast-image`}
                    alt={t('image-alt.powered-by-toast')}
                    className='mt-4'
                    width={164}
                    src={POWERED_BY_TOAST_IMAGE_URL}
                  />
                </div>
              </ModalFooter>
            </Modal>
          </Form>
        </FormikProvider>
      )}
    </>
  )
}

const PhoneNumberInput: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext<SendDownloadAppLinkSchema>()
  return (
    <div className='mt-6'>
      <NumberInputField
        {...trackSendDownloadAppLink('phone-number')}
        testId='test-number-input'
        format='( ### ) ###-####'
        allowEmptyFormatting
        mask='_'
        name='phoneNumber'
        label={t('download-app-modal.phone-number-input.label')}
        onChange={(number) =>
          setFieldValue('phoneNumber', number.formattedValue)
        }
      />
    </div>
  )
}

const SendDownloadAppLinkButton: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { isValid } = useFormikContext<SendDownloadAppLinkSchema>()
  return (
    <Button
      {...trackSendDownloadAppLink('send-link')}
      testId='test-send-download-link-btn'
      className='w-full'
      form='send-download-app-link-form'
      disabled={!isValid}
      type='submit'
    >
      {t('download-app-modal.send-download-link-button')}
    </Button>
  )
}
