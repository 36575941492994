import * as React from 'react'
import { useFormikContext } from 'formik'
import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table'
import { NumberInputField } from '@toasttab/buffet-pui-forms'
import { useTranslation } from '@local/translations'
import { trackDirectDepositSplitModal } from '@local/track'
import { BankAccount } from '../../../../api-documents'
import { DirectDepositSplitSchema } from '../schema'

const columnHelper = createColumnHelper<BankAccount>()
export const usePercentSplitTableColumns = (): ColumnDef<
  BankAccount,
  any
>[] => {
  const { t } = useTranslation()
  return React.useMemo(
    () => [
      columnHelper.accessor('bankName', {
        id: 'bank-name',
        header: t('direct-deposit-split-modal.bank-name-header'),
        cell: (info) => info.getValue()
      }),
      columnHelper.accessor('accountNumber', {
        id: 'account-number',
        header: t('direct-deposit-split-modal.bank-account-header'),
        cell: (info) => info.getValue()
      }),
      columnHelper.accessor((originalRow) => originalRow, {
        id: 'percentage',
        header: t('direct-deposit-split-modal.percent-header'),
        cell: (info) => {
          return <PercentInputField row={info.row} />
        }
      })
    ],
    [t]
  )
}

const PercentInputField: React.FunctionComponent<{ row: Row<BankAccount> }> = ({
  row
}) => {
  const { errors } = useFormikContext<DirectDepositSplitSchema>()
  const formInvalidProps =
    typeof errors.bankAccounts === 'string' ? { invalid: true } : {}

  return (
    <NumberInputField
      {...trackDirectDepositSplitModal('percent')}
      {...formInvalidProps}
      className='text-left'
      decimalScale={0}
      id={row.original.id}
      maxLength={3}
      name={`bankAccounts.${row.index}.percentage`}
      prefix='%'
    />
  )
}
