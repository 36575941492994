import * as React from 'react'

import { BankIcon, AddIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { trackAddBankAccountPanel } from '@local/track'
import { useTranslation } from '@local/translations'

import { useShowBankAccountPanel } from './utils'
import { PlaidModal } from '../../payment-method/directDeposit'

/**
 * AddBankAccountPanel component
 */
export const AddBankAccountPanel: React.FunctionComponent<TestIdentifiable> = ({
  testId
}) => {
  const { t } = useTranslation()
  const [isPlaidModalOpen, setIsPlaidModalOpen] = React.useState<boolean>(false)
  const showBankAccountPanel = useShowBankAccountPanel()
  return showBankAccountPanel ? (
    <Panel
      testId={testId}
      title={t('direct-deposit-add.header')}
      subtitle={t('direct-deposit-add.description')}
    >
      <div className='border-dashed rounded-lg border-2 px-10 pt-6 pb-10 -mt-2 space-y-4'>
        <div className='flex justify-center'>
          <BankIcon
            accessibility='decorative'
            className='text-secondary'
            size='md'
          />
        </div>
        <div className='space-y-2'>
          <div
            className='font-semibold text-center block'
            data-testid='no-bank-account-heading'
          >
            {t('direct-deposit.no-bank-accounts')}
          </div>
          <div
            className='text-center text-secondary block'
            data-testid='no-bank-account-description'
          >
            {t('direct-deposit.no-bank-account-description')}
          </div>
        </div>
        <div className='flex justify-center'>
          <Button
            onClick={() => setIsPlaidModalOpen(true)}
            variant='secondary'
            className='my-auto bg-transparent justify-center'
            {...trackAddBankAccountPanel('add')}
            iconLeft={<AddIcon accessibility='decorative' />}
          >
            {t('direct-deposit.add-bank-account')}
          </Button>
        </div>
        {isPlaidModalOpen && (
          <PlaidModal onClose={() => setIsPlaidModalOpen(false)} />
        )}
      </div>
    </Panel>
  ) : null
}
