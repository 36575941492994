import * as React from 'react'
import { useQuery } from '@apollo/client'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { Image } from '@toasttab/buffet-pui-image'
import { trackDownloadApp, useTracking } from '@local/track'
import { useEmployee } from '@local/contexts'
import { useTranslation } from '@local/translations'
import {
  DownloadSteps,
  HeaderContent,
  PayCardActivationDisclaimer,
  PayCardEligibility,
  SmsDisclaimer,
  SuttonDisclaimer
} from '../components'
import {
  EmployeeForDownloadApp,
  useLogDownloadAppLinkSentMutation
} from '../api-documents'
import { POWERED_BY_TOAST_IMAGE_URL } from '../../payCard'
import { DownloadAppModalProps } from '../DownloadAppModal'

export const MobileDownloadAppModal: React.FunctionComponent<
  DownloadAppModalProps
> = ({ testId = 'download-app-modal', onClose }) => {
  const { t } = useTranslation()

  const employee = useEmployee()
  const { data } = useQuery(EmployeeForDownloadApp, {
    variables: { employeeId: employee.id }
  })
  const downloadAppLinkSentMutation = useLogDownloadAppLinkSentMutation()

  const handleClick = async () => {
    downloadAppLinkSentMutation.mutate({
      email: data?.findEmployeeById?.user?.email,
      fullName: data?.findEmployeeById?.user?.name?.formatted,
      phoneNumber:
        data?.findEmployeeById?.contact?.telephoneNumber?.telephoneNumber
    })

    window.open('https://mytoast.page.link/mVFa', '_blank')
    onClose()
    return
  }

  const { track } = useTracking()
  return (
    <Modal
      isOpen={true}
      overflowBehavior='body'
      shouldCloseOnEsc
      onRequestClose={() => {
        track('employee-payment-method.download-app.close')
        onClose()
      }}
      size='md'
      testId={`${testId}`}
    >
      <ModalHeader>
        <HeaderContent testId={testId} />
      </ModalHeader>
      <ModalBody>
        <div className='type-default text-default'>
          <PayCardEligibility testId={testId} />
          <DividingLine />
          <DownloadSteps />
        </div>
        <PayCardActivationDisclaimer testId={testId} />
        <SmsDisclaimer />
        <SuttonDisclaimer />
      </ModalBody>
      <ModalFooter>
        <div className='flex flex-col w-full place-items-center'>
          <DownloadButton handleClick={handleClick} />
          <Image
            testId={`${testId}-powered-by-toast-image`}
            alt={t('image-alt.powered-by-toast')}
            className='mt-4'
            width={164}
            src={POWERED_BY_TOAST_IMAGE_URL}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

const DownloadButton: React.FunctionComponent<{
  handleClick: () => void
}> = ({ handleClick }) => {
  const { t } = useTranslation()
  return (
    <Button
      {...trackDownloadApp('download')}
      testId='test-send-download-link-btn'
      className='w-full'
      onClick={handleClick}
    >
      {t('download-app-modal.download-button')}
    </Button>
  )
}
