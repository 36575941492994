import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useEmployee } from '@local/contexts'
import { useTranslation } from '@local/translations'

export const PayCardActivationDisclaimer: React.FunctionComponent<
  TestIdentifiable
> = ({ testId }) => {
  const isFromSplashScreen =
    useEmployee().paymentMethod === 'EmptyPaymentMethod'

  const { t } = useTranslation()
  return (
    <div
      className='mt-3 type-subhead text-secondary'
      data-testid={`${testId}-disclaimer-text`}
    >
      {isFromSplashScreen
        ? t('download-app-modal.disclaimer.from-splash-screen')
        : t('download-app-modal.disclaimer.from-banner')}
    </div>
  )
}
