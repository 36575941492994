import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { App } from './app/App'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-hr',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],

  sentry: {
    publicKey: '238c7bff9c2d3f9f30c44dc9d0158ad6@o37442',
    projectId: '4506628469751808',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'employee-payment-method-spa'
