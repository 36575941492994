import * as React from 'react'
import { trackDownloadApp } from '@local/track'
import { useTranslation } from '@local/translations'

export const SmsDisclaimer: React.FunctionComponent = () => {
  const { Trans } = useTranslation()
  return (
    <div className='mt-6 px-6 text-center type-caption text-gray-75'>
      <Trans
        i18nKey={'download-app-modal.sms-disclaimer'}
        components={[
          <a
            {...trackDownloadApp('sms-disclaimer.terms')}
            href='https://pos.toasttab.com/terms-of-service'
            target='_blank'
            rel='noopener noreferrer'
          />,
          <a
            {...trackDownloadApp('sms-disclaimer.privacy')}
            href='https://pos.toasttab.com/privacy'
            target='_blank'
            rel='noopener noreferrer'
          />
        ]}
      />
    </div>
  )
}
