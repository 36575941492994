import * as React from 'react'
import { Modal, ModalBody, ModalFooter } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Image } from '@toasttab/buffet-pui-image'
import { trackSendDownloadAppLinkConfirmation, useTracking } from '@local/track'
import { useTranslation } from '@local/translations'
import { DownloadAppModalProps } from '../DownloadAppModal'
import { REQUEST_SENT_IMAGE_URL } from '../images'

export const SendDownloadAppLinkConfirmationModal = ({
  testId = 'send-download-app-link-confirmation-modal',
  onClose
}: DownloadAppModalProps) => {
  const { t } = useTranslation()
  const { track } = useTracking()

  return (
    <Modal
      isOpen={true}
      overflowBehavior='modal'
      shouldCloseOnEsc
      onRequestClose={() => {
        track(
          'employee-payment-method.send-download-app-link-confirmation.close'
        )
        onClose()
      }}
      size='md'
      testId={`${testId}`}
    >
      <ModalBody>
        <div className='flex justify-center items-center'>
          <Image
            data-testid='request-sent-image'
            alt={t('image-alt.request-sent')}
            className='request-sent-image'
            src={REQUEST_SENT_IMAGE_URL}
          />
        </div>

        <div className='text-default type-large font-bold text-center mt-6 mb-2'>
          {t('download-app-confirmation-modal.header')}
        </div>
        <div className='text-secondary type-default text-center mb-28'>
          {t('download-app-confirmation-modal.description')}
        </div>
      </ModalBody>
      <ModalFooter stackOnMobile>
        <Button
          {...trackSendDownloadAppLinkConfirmation('confirm')}
          size='auto'
          className='w-full'
          onClick={onClose}
        >
          {t('download-app-confirmation-modal.confirm-button')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
