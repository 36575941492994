import * as React from 'react'

import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

import { ConfirmationModal } from '@local/confirmation-modal'
import { trackDirectDepositAccounts } from '@local/track'
import { useEmployeeDirectDepositBankAccounts } from '@local/contexts'
import { useTranslation } from '@local/translations'

import { useDeleteBankAccountMutation } from './api-documents'

export type DeleteBankAccountConfirmationModalProps = {
  bankAccountId: string
  onClose: VoidFunction
} & TestIdentifiable

/**
 * DeleteBankAccountConfirmationModal component
 * Allows the user to confirm whether they'd like to move forward with deleting the
 * direct deposit bank account
 * @param testId a test id for the modal
 * @param bankAccountId the guid of the bank account being deleted
 * @param onClose the function to run on either success or error
 * @constructor
 */
export const DeleteBankAccountConfirmationModal: React.FunctionComponent<
  DeleteBankAccountConfirmationModalProps
> = ({
  testId = 'delete-bank-account-confirmation-modal',
  bankAccountId,
  onClose
}) => {
  const { t } = useTranslation()

  const bankAccountCount = useEmployeeDirectDepositBankAccounts().length
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const deleteBankAccountMutation = useDeleteBankAccountMutation(
    () => {
      showSuccessSnackBar(
        t('direct-deposit.delete-account-confirmation.success', {
          count: bankAccountCount
        })
      )
      onClose()
    },
    () => {
      showErrorSnackBar(t('direct-deposit.delete-account-confirmation.error'))
      onClose()
    }
  )

  return (
    <ConfirmationModal
      testId={testId}
      isOpen={true}
      showIcon={false}
      heading={t('direct-deposit.delete-account-confirmation.heading')}
      destructive
      cancelButtonProps={{
        ...trackDirectDepositAccounts('cancel-delete'),
        text: t('direct-deposit.delete-account-confirmation.cancel'),
        onClick: onClose
      }}
      confirmButtonProps={{
        ...trackDirectDepositAccounts('delete'),
        text: t('direct-deposit.delete-account-confirmation.confirm'),
        onClick: () => deleteBankAccountMutation.mutate({ id: bankAccountId })
      }}
    >
      {t('direct-deposit.delete-account-confirmation', { count: 1 })}
    </ConfirmationModal>
  )
}
