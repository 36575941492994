import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent
} from 'react-plaid-link'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import { SetStateAction } from 'react'
import { PlaidErrorModalProps } from './PlaidErrorModal'
import { useSaveBankAccountMutation } from '../../payment-method-panel/paymentMethodSelector/api-documents'

export const useOnPlaidSuccess = (
  onClose: VoidFunction
): ((publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => void) => {
  const { t } = useTranslation()

  const saveBankAccountMutation = useSaveBankAccountMutation(
    () => {
      showSuccessSnackBar(t('payment-method-change.success'))
      onClose()
    },
    () => {
      showErrorSnackBar(t('payment-method-change.error'))
      onClose()
    }
  )
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()

  return (publicToken, metadata) => {
    saveBankAccountMutation.mutate({
      plaidPublicToken: publicToken,
      bankName: metadata.institution?.name
    })
  }
}

const INSTITUTION_NOT_FOUND = 'institution_not_found'
const FIXABLE_PLAID_LINK_ERROR_CODES = new Set([
  'INSUFFICIENT_CREDENTIALS',
  'INVALID_CREDENTIALS',
  'INVALID_MFA',
  'USER_INPUT_TIMEOUT',
  'USER_SETUP_REQUIRED',
  'ACCESS_NOT_GRANTED',
  'ITEM_LOCKED',
  'INVALID_ACCOUNT_NUMBER',
  'INVALID_LINK_TOKEN',
  'RECAPTCHA_REQUIRED',
  'RECAPTCHA_BAD'
])

export const useOnPlaidExit = (
  setPlaidErrorModalProps: {
    (
      value: SetStateAction<{
        isOpen: boolean
        props?: Pick<PlaidErrorModalProps, 'institutionNotFound'>
      }>
    ): void
  },
  onClose: VoidFunction
) => {
  return (err: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
    if (err?.error_code) {
      // Log important troubleshooting information - this is captured by breadcrumbs in Sentry
      console.log(err, metadata, metadata?.institution)
      // Log error with error_code for tracking and alerting
      console.error(`PlaidLinkErrorOnExit: ${err.error_code}`)
      onClose()
    }
    if (metadata?.status === INSTITUTION_NOT_FOUND && err === null) {
      setPlaidErrorModalProps({
        isOpen: true,
        props: { institutionNotFound: true }
      })
    } else if (
      err?.error_code &&
      !FIXABLE_PLAID_LINK_ERROR_CODES.has(err.error_code)
    ) {
      setPlaidErrorModalProps({
        isOpen: true,
        props: { institutionNotFound: false }
      })
    } else {
      onClose()
    }
  }
}

const INSTITUTION_PLAID_LINK_ERROR_CODES = new Set([
  'INSTITUTION_DOWN',
  'INSTITUTION_NOT_AVAILABLE',
  'INSTITUTION_NOT_RESPONDING',
  'INSTITUTION_NOT_ENABLED_IN_ENVIRONMENT',
  'INSTITUTION_NOT_FOUND',
  'INSTITUTION_REGISTRATION_REQUIRED',
  'UNAUTHORIZED_INSTITUTION',
  'INSTITUTION_NO_LONGER_SUPPORTED',
  'institution-poor-health-error'
])

export const useOnPlaidEvent = () => {
  return (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    if (eventName === 'ERROR') {
      // Log important troubleshooting information - this is captured by breadcrumbs in Sentry
      console.log(metadata)
      // Log error with error_code for tracking and alerting
      console.error(`PlaidLinkErrorOnEvent: ${metadata?.error_code}`)

      // If the error could indicate that an institution is down or unavailable, log the institution name
      if (
        metadata?.error_code &&
        INSTITUTION_PLAID_LINK_ERROR_CODES.has(metadata.error_code)
      ) {
        console.error(
          `PlaidLinkInstitutionError: ${metadata.error_code} ${metadata.institution_name}`
        )
      }
    }
  }
}
