import { useEmployee, useUser } from '@local/contexts'

import { useTranslation } from '@local/translations'
import { ReauthContainerProps } from '@toasttab/ec-session'

export const useReauthProps = (): Omit<ReauthContainerProps, 'children'> => {
  const user = useUser()
  const employee = useEmployee()
  const handleCancel = () => {
    window.location.href = `/${user.client}/employees/${employee.id}/employment`
  }

  const { t } = useTranslation()
  return {
    header: t('reauthentication-modal.header'),
    message: t('reauthentication-modal.message'),
    cancelButtonProps: {
      'data-toast-track-id': 'employee-payment-method.reauth.cancel',
      label: t('reauthentication-modal.cancel'),
      canCancel: true,
      onClick: handleCancel
    },
    loginButtonProps: {
      'data-toast-track-id': 'employee-payment-method.reauth.login',
      label: t('reauthentication-modal.login')
    }
  }
}
