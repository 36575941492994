import * as React from 'react'
import { BanquetProps } from 'banquet-runtime-modules'
import { ApolloProvider } from '@apollo/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { EcProps } from '@toasttab/ec-banquet-props'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'

import { TranslationProvider } from '@local/translations'
import { getClient } from '@local/api/client'

import { AppContext } from './AppContext'
import { PaymentMethodPage } from './payment-method'

// todo move prop type to ec-spa-common
type AppProps = {
  spaProps?: {
    employeeId: string
  }
  ec?: EcProps
} & BanquetProps

export function App(props?: AppProps) {
  // todo if not employee or not customerCode console log
  const user = props?.ec?.user
  const employeeId = props?.spaProps?.employeeId || ''

  const [restClient] = React.useState(() => new QueryClient())
  const [apolloClient] = React.useState(() => getClient())

  return !!user && !!employeeId ? (
    <TranslationProvider>
      <QueryClientProvider client={restClient}>
        <ApolloProvider client={apolloClient}>
          <AppContext user={user} employeeId={employeeId}>
            <SnackBarProvider>
              <PaymentMethodPage />
            </SnackBarProvider>
          </AppContext>
        </ApolloProvider>
      </QueryClientProvider>
    </TranslationProvider>
  ) : null
}
